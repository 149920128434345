<template>
  <div
    v-if="showNotification"
    :id="$options.name"
  >
    <p-message
      v-if="isError"
      severity="error"
      @close="dismiss"
    >
      <p class="mb-4">
        {{ userStore.error }}
      </p>

      <div
        v-if="hasErrors"
        class="text-left"
      >
        <div
          v-for="errorItem in userStore.errors"
          :key="errorItem.key"
          class="mb-4"
        >
          <h2 class="pb-2 text-xl">{{ titlecase(errorItem.key) }}:</h2>
          <ul class="ml-5 list-disc">
            <li
              v-for="(item, index) in errorItem.value"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </p-message>

    <p-message
      v-if="isBillingError"
      severity="error"
      @close="dismiss"
    >
      <p class="mb-4">
        {{ userStore.billingError }}
      </p>

      <div class="flex items-center">
        <!-- @vue-ignore href is allowed -->
        <p-button
          as="a"
          :href="route('billing.choose.type')"
          icon="fa-light fa-credit-card"
          label="Add A Card"
        />
      </div>
    </p-message>

    <p-message
      v-if="isStatus"
      severity="success"
      @close="dismiss"
    >
      {{ statusToDisplay }}
    </p-message>
  </div>
</template>

<script lang="ts">
import {useUserStore} from "@/js/stores/user";
import {lowerCase, startCase} from "lodash-es";
import {defineComponent} from "vue";

export default defineComponent({
  name: "TtrAlert",

  setup() {
    const userStore = useUserStore();
    return {userStore};
  },

  data: (): {dismissNotification: boolean} => {
    return {
      dismissNotification: false,
    };
  },

  computed: {
    isError() {
      return this.userStore.error != null;
    },

    hasErrors() {
      return this.userStore.errors.length > 0;
    },

    isBillingError() {
      return this.userStore.billingError != null;
    },

    isStatus() {
      return this.userStore.status != null;
    },

    showNotification() {
      if (!this.dismissNotification) {
        if (this.userStore.status != null) {
          return true;
        }
        if (this.userStore.error != null) {
          return true;
        }
        if (this.hasErrors) {
          return true;
        }
        if (this.userStore.billingError != null) {
          return true;
        }
      }
      return false;
    },

    statusToDisplay() {
      switch (this.userStore.status) {
      case "two-factor-authentication-enabled":
        return "Two factor authentication has been enabled";
      case "two-factor-authentication-disabled":
        return "Two factor authentication has been disabled";
      case "recovery-codes-generated":
        return "Recovery codes have been regenerated";
      default:
        return this.userStore.status;
      }
    },
  },

  methods: {
    clearStatus() {
      this.userStore.clearStatus();
    },
    titlecase(value: string) {
      return startCase(lowerCase(value));
    },
    dismiss() {
      this.clearStatus();
    },
  },
});
</script>
