/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import {debounce} from "lodash-es";
import {createApp, defineAsyncComponent} from "vue";
import {ZiggyVue} from "ziggy-js";

import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import DataTable from "primevue/datatable";
import DatePicker from "primevue/datepicker";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import FloatLabel from "primevue/floatlabel";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Menu from "primevue/menu";
import Message from "primevue/message";
import Paginator from "primevue/paginator";
import Popover from "primevue/popover";
import RadioButton from "primevue/radiobutton";
import Row from "primevue/row";
import Select from "primevue/select";
import SelectButton from "primevue/selectbutton";
import Tab from "primevue/tab";
import TabList from "primevue/tablist";
import TabPanel from "primevue/tabpanel";
import TabPanels from "primevue/tabpanels";
import Tabs from "primevue/tabs";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import ToggleButton from "primevue/togglebutton";
import ToggleSwitch from "primevue/toggleswitch";
import Tooltip from "primevue/tooltip";

import {customTheme, defaultThemeColors} from "@/js/theme";
import {definePreset} from "@primevue/themes";
import Aura from "@primevue/themes/aura";
import colors from "tailwindcss/colors";

import HighchartsMore from "highcharts/highcharts-more";
import LoadAccessibility from "highcharts/modules/accessibility";
import SolidGauge from "highcharts/modules/solid-gauge";

const themeIndex = productType === "ignite" ? 1 : 0;

const TtrSuccessPrimeVueTheme = definePreset(Aura, {
  semantic: {
    primary: customTheme.themes[themeIndex].extend.colors.primary,
    info: defaultThemeColors.navy,
    colorScheme: {
      light: {
        surface: colors.neutral,
      },
      dark: {
        surface: colors.neutral,
      },
    },
  },
});

//Ttr Stuff
//no need for dynamic on this one as it's loaded on EVERY page basically
import "@/js/bootstrap";
import TtrAlert from "@/js/components/misc/TtrAlert.vue";
import {useUserStore} from "@/js/stores/user";
import {PiniaDebounce} from "@pinia/plugin-debounce";
import {createPinia} from "pinia";
const pinia = createPinia();

pinia.use(PiniaDebounce(debounce));

LoadAccessibility(Highcharts);
HighchartsMore(Highcharts);
SolidGauge(Highcharts);

const TtrAccessTokens = defineAsyncComponent(() => import("@/js/components/accessTokens/TtrAccessTokens.vue"));
const TtrActionLink = defineAsyncComponent(() => import("@/js/components/misc/TtrActionLink.vue"));
const TtrAddThreadsToDealModal = defineAsyncComponent(() => import("@/js/components/reports/ignite/TtrAddThreadsToDealModal.vue"));
const TtrAdminOptimizerAnalytics = defineAsyncComponent(() => import("@/js/components/admin/tracking/TtrAdminOptimizerAnalytics.vue"));
const TtrIntlCurrencyFormat = defineAsyncComponent(() => import("@/js/components/misc/TtrIntlCurrencyFormat.vue"));
const TtrInputGroup = defineAsyncComponent(() => import("@/js/components/misc/TtrInputGroup.vue"));
const TtrAdminAgentsList = defineAsyncComponent(() => import("@/js/components/admin/TtrAdminAgentsList.vue"));
const TtrAdminCompaniesList = defineAsyncComponent(() => import("@/js/components/admin/TtrAdminCompaniesList.vue"));
const TtrAdminTracking = defineAsyncComponent(() => import("@/js/components/admin/TtrAdminTracking.vue"));
const TtrAdminUsersList = defineAsyncComponent(() => import("@/js/components/admin/TtrAdminUsersList.vue"));
const TtrB2BSaasReport = defineAsyncComponent(() => import("@/js/components/admin/TtrB2BSaasReport.vue"));
const TtrAdvancedFilters = defineAsyncComponent(() => import("@/js/components/filtering/TtrAdvancedFilters.vue"));
const TtrAgentOptimizerData = defineAsyncComponent(() => import("@/js/components/optimizer/TtrAgentOptimizerData.vue"));
const TtrAgentsList = defineAsyncComponent(() => import("@/js/components/agents/TtrAgentsList.vue"));
const TtrCustomersList = defineAsyncComponent(() => import("@/js/components/customers/TtrCustomersList.vue"));
const TtrBlockReport = defineAsyncComponent(() => import("@/js/components/misc/TtrBlockReport.vue"));
const TtrBulkAgentSelect = defineAsyncComponent(() => import("@/js/components/agents/TtrBulkAgentSelect.vue"));
const TtrBulkAgentSelectGmail = defineAsyncComponent(() => import("@/js/components/agents/TtrBulkAgentSelectGmail.vue"));
const TtrBulkAgentSelectMimecastSlow = defineAsyncComponent(() => import("@/js/components/agents/TtrBulkAgentSelectMimecastSlow.vue"));
const TtrBusinessHoursBox = defineAsyncComponent(() => import("@/js/components/companies/TtrBusinessHoursBox.vue"));
const TtrChooseBilling = defineAsyncComponent(() => import("@/js/components/billing/TtrChooseBilling.vue"));
const TtrCollapse = defineAsyncComponent(() => import("@/js/components/misc/TtrCollapse.vue"));
const TtrCrmAddActiveCampaignCredentials = defineAsyncComponent(() => import("@/js/components/crms/TtrCrmAddActiveCampaignCredentials.vue"));
const TtrCrmAddMaropostCredentials = defineAsyncComponent(() => import("@/js/components/crms/TtrCrmAddMaropostCredentials.vue"));
const TtrCrmMapping = defineAsyncComponent(() => import("@/js/components/crms/TtrCrmMapping.vue"));
const TtrCustomerGroupsList = defineAsyncComponent(() => import("@/js/components/customerGroups/TtrCustomerGroupsList.vue"));
const TtrListDeals = defineAsyncComponent(() => import("@/js/components/ignite/deals/TtrListDeals.vue"));
const TtrDealStagesList = defineAsyncComponent(() => import("@/js/components/ignite/dealStages/TtrDealStagesList.vue"));
const TtrDealStagesSetup = defineAsyncComponent(() => import("@/js/components/ignite/auth/TtrDealStagesSetup.vue"));
const TtrDeleteAccountButton = defineAsyncComponent(() => import("@/js/components/users/TtrDeleteAccountButton.vue"));
const TtrDeleteCompanyButton = defineAsyncComponent(() => import("@/js/components/users/TtrDeleteCompanyButton.vue"));
const TtrDeleteGroupMailboxButton = defineAsyncComponent(() => import("@/js/components/groupMailboxes/TtrDeleteGroupMailboxButton.vue"));
const TtrDeleteTeamButton = defineAsyncComponent(() => import("@/js/components/teams/TtrDeleteTeamButton.vue"));
const TtrDomainFilters = defineAsyncComponent(() => import("@/js/components/filtering/TtrDomainFilters.vue"));
const TtrDownloadExcelButton = defineAsyncComponent(() => import("@/js/components/downloadButtons/TtrDownloadExcelButton.vue"));
const TtrDownloadHybridButton = defineAsyncComponent(() => import("@/js/components/downloadButtons/TtrDownloadHybridButton.vue"));
const TtrDownloadPdfButton = defineAsyncComponent(() => import("@/js/components/downloadButtons/TtrDownloadPdfButton.vue"));
const TtrEditOptimizerSettings = defineAsyncComponent(() => import("@/js/components/optimizer/TtrEditOptimizerSettings.vue"));
const TtrOptimizerSetupPreview = defineAsyncComponent(() => import("@/js/components/optimizer/TtrOptimizerSetupPreview.vue"));
const TtrEditAgent = defineAsyncComponent(() => import("@/js/components/agents/TtrEditAgent.vue"));
const TtrAgentInvites = defineAsyncComponent(() => import("@/js/components/agents/TtrAgentInvites.vue"));
const TtrEditCloseEmailAddresses = defineAsyncComponent(() => import("@/js/components/companies/TtrEditCloseEmailAddresses.vue"));
const TtrEditConversationThreadingStyle = defineAsyncComponent(() => import("@/js/components/companies/TtrEditConversationThreadingStyle.vue"));
const TtrEditCustomerGroup = defineAsyncComponent(() => import("@/js/components/customerGroups/TtrEditCustomerGroup.vue"));
const TtrEditCustomerGroupCrm = defineAsyncComponent(() => import("@/js/components/customerGroups/TtrEditCustomerGroupCrm.vue"));
const TtrEditCustomerGroupHubspot = defineAsyncComponent(() => import("@/js/components/customerGroups/TtrEditCustomerGroupHubspot.vue"));
const TtrEditCustomerGroupSalesforce = defineAsyncComponent(() => import("@/js/components/customerGroups/TtrEditCustomerGroupSalesforce.vue"));
const TtrEditCustomerGroupZoho = defineAsyncComponent(() => import("@/js/components/customerGroups/TtrEditCustomerGroupZoho.vue"));
const TtrEditDeal = defineAsyncComponent(() => import("@/js/components/ignite/deals/TtrEditDeal.vue"));
const TtrEditEwsAgent = defineAsyncComponent(() => import("@/js/components/agents/TtrEditEwsAgent.vue"));
const TtrGroupMailboxList = defineAsyncComponent(() => import("@/js/components/groupMailboxes/TtrGroupMailboxList.vue"));
const TtrEditGroupMailbox = defineAsyncComponent(() => import("@/js/components/groupMailboxes/TtrEditGroupMailbox.vue"));
const TtrEditInternalDomains = defineAsyncComponent(() => import("@/js/components/companies/TtrEditInternalDomains.vue"));
const TtrLeadSourceList = defineAsyncComponent(() => import("@/js/components/ignite/leadSources/TtrLeadSourceList.vue"));
const TtrEditLeadSource = defineAsyncComponent(() => import("@/js/components/ignite/leadSources/TtrEditLeadSource.vue"));
const TtrEditDealStage = defineAsyncComponent(() => import("@/js/components/ignite/dealStages/TtrEditDealStage.vue"));
const TtrEditManualSubscription = defineAsyncComponent(() => import("@/js/components/admin/TtrEditManualSubscription.vue"));
const TtrEditMicrosoftProxyAddress = defineAsyncComponent(() => import("@/js/components/microsoftProxyAddresses/TtrEditMicrosoftProxyAddress.vue"));
const TtrEditPagesPermissions = defineAsyncComponent(() => import("@/js/components/companies/TtrEditPagesPermissions.vue"));
const TtrEditPromotion = defineAsyncComponent(() => import("@/js/components/promotions/TtrEditPromotion.vue"));
const TtrTeamList = defineAsyncComponent(() => import("@/js/components/teams/TtrTeamList.vue"));
const TtrEditTeam = defineAsyncComponent(() => import("@/js/components/teams/TtrEditTeam.vue"));
const TtrEditUser = defineAsyncComponent(() => import("@/js/components/users/TtrEditUser.vue"));
const TtrEmailUsernameFilters = defineAsyncComponent(() => import("@/js/components/filtering/TtrEmailUsernameFilters.vue"));
const TtrFirstRunModals = defineAsyncComponent(() => import("@/js/components/reports/TtrFirstRunModals.vue"));
const TtrFooterBanner = defineAsyncComponent(() => import("@/js/components/misc/TtrFooterBanner.vue"));
const TtrGoogleBulkAddDescriptionModal = defineAsyncComponent(() => import("@/js/components/auth/TtrGoogleBulkAddDescriptionModal.vue"));
const TtrIgniteSetup = defineAsyncComponent(() => import("@/js/components/ignite/auth/TtrIgniteSetup.vue"));
const TtrIngestionProgressBar = defineAsyncComponent(() => import("@/js/components/misc/TtrIngestionProgressBar.vue"));
const TtrInviteAgent = defineAsyncComponent(() => import("@/js/components/agents/TtrInviteAgent.vue"));
const TtrInstallOptimizerModal = defineAsyncComponent(() => import("@/js/components/agents/TtrInstallOptimizerModal.vue"));
const TtrJoinCompanyRequestDropdown = defineAsyncComponent(() => import("@/js/components/users/TtrJoinCompanyRequestDropdown.vue"));
const TtrLeaveCalendarDay = defineAsyncComponent(() => import("@/js/components/companies/TtrLeaveCalendarDay.vue"));
const TtrLeaveDateRange = defineAsyncComponent(() => import("@/js/components/companies/TtrLeaveDateRange.vue"));
const TtrLinkMailbox = defineAsyncComponent(() => import("@/js/components/agents/TtrLinkMailbox.vue"));
const TtrLogsAudits = defineAsyncComponent(() => import("@/js/components/logs/TtrLogsAudits.vue"));
const TtrLogsConversations = defineAsyncComponent(() => import("@/js/components/logs/TtrLogsConversations.vue"));
const TtrLogsMessages = defineAsyncComponent(() => import("@/js/components/logs/TtrLogsMessages.vue"));
const TtrManualSubscriptionsList = defineAsyncComponent(() => import("@/js/components/admin/TtrManualSubscriptionsList.vue"));
const TtrMaxReplyTimeFilters = defineAsyncComponent(() => import("@/js/components/filtering/TtrMaxReplyTimeFilters.vue"));
const TtrMimecastAuthForm = defineAsyncComponent(() => import("@/js/components/auth/TtrMimecastAuthForm.vue"));
const TtrNavbar = defineAsyncComponent(() => import("@/js/components/misc/TtrNavbar.vue"));
const TtrOAuthClients = defineAsyncComponent(() => import("@/js/components/accessTokens/TtrOAuthClients.vue"));
const TtrOnboardingSummary = defineAsyncComponent(() => import("@/js/components/agents/TtrOnboardingSummary.vue"));
const TtrPendingJoinRequest = defineAsyncComponent(() => import("@/js/components/auth/TtrPendingJoinRequest.vue"));
const TtrPromptToInstallOptimizerModal = defineAsyncComponent(() => import("@/js/components/users/TtrPromptToInstallOptimizerModal.vue"));
const TtrPromptToRedirectModal = defineAsyncComponent(() => import("@/js/components/users/TtrPromptToRedirectModal.vue"));
const TtrReplyTimeGoals = defineAsyncComponent(() => import("@/js/components/filtering/TtrReplyTimeGoals.vue"));
const TtrReportActionsViews = defineAsyncComponent(() => import("@/js/components/filtering/TtrReportActionsViews.vue"));
const TtrReportDateRange = defineAsyncComponent(() => import("@/js/components/filtering/TtrReportDateRange.vue"));
const TtrReportsComparative = defineAsyncComponent(() => import("@/js/components/reports/TtrReportsComparative.vue"));
const TtrReportsCustomer = defineAsyncComponent(() => import("@/js/components/reports/TtrReportsCustomer.vue"));
const TtrReportsGroupMailbox = defineAsyncComponent(() => import("@/js/components/reports/TtrReportsGroupMailbox.vue"));
const TtrReportsInteractions = defineAsyncComponent(() => import("@/js/components/reports/TtrReportsInteractions.vue"));
const TtrReportsOverview = defineAsyncComponent(() => import("@/js/components/reports/TtrReportsOverview.vue"));
const TtrReportsProductivity = defineAsyncComponent(() => import("@/js/components/reports/TtrReportsProductivity.vue"));
const TtrReportsSla = defineAsyncComponent(() => import("@/js/components/reports/TtrReportsSla.vue"));
const TtrReportsTeam = defineAsyncComponent(() => import("@/js/components/reports/TtrReportsTeam.vue"));
const TtrReportsTrend = defineAsyncComponent(() => import("@/js/components/reports/TtrReportsTrend.vue"));
const TtrReportsLeadSources = defineAsyncComponent(() => import("@/js/components/reports/ignite/TtrReportsLeadSources.vue"));
const TtrReportsSalesRep = defineAsyncComponent(() => import("@/js/components/reports/ignite/TtrReportsSalesRep.vue"));
const TtrReportsAlerts = defineAsyncComponent(() => import("@/js/components/reports/TtrReportsAlerts.vue"));
const TtrResellerAgentsList = defineAsyncComponent(() => import("@/js/components/reseller/TtrResellerAgentsList.vue"));
const TtrResellerUsersList = defineAsyncComponent(() => import("@/js/components/reseller/TtrResellerUsersList.vue"));
const TtrViewsList = defineAsyncComponent(() => import("@/js/components/views/TtrViewsList.vue"));
const TtrSelectCompanyToJoin = defineAsyncComponent(() => import("@/js/components/auth/TtrSelectCompanyToJoin.vue"));
const TtrSidebar = defineAsyncComponent(() => import("@/js/components/misc/TtrSidebar.vue"));
const TtrStatLogModal = defineAsyncComponent(() => import("@/js/components/reports/TtrStatLogModal.vue"));
const TtrSuccessSetup = defineAsyncComponent(() => import("@/js/components/auth/TtrSuccessSetup.vue"));
const TtrThreadFilters = defineAsyncComponent(() => import("@/js/components/filtering/TtrThreadFilters.vue"));
const TtrThreadFiltersPdf = defineAsyncComponent(() => import("@/js/components/filtering/TtrThreadFiltersPdf.vue"));
const TtrTimeZone = defineAsyncComponent(() => import("@/js/components/misc/TtrTimeZone.vue"));
const TtrTooltip = defineAsyncComponent(() => import("@/js/components/misc/TtrTooltip.vue"));
const TtrTrialCompletionStatus = defineAsyncComponent(() => import("@/js/components/users/TtrTrialCompletionStatus.vue"));
const TtrTwoFactorChallenge = defineAsyncComponent(() => import("@/js/components/auth/TtrTwoFactorChallenge.vue"));
const TtrUploadContactsCsv = defineAsyncComponent(() => import("@/js/components/customers/TtrUploadContactsCsv.vue"));
const TtrUploadTeamCsv = defineAsyncComponent(() => import("@/js/components/teams/TtrUploadTeamCsv.vue"));
const TtrUsersList = defineAsyncComponent(() => import("@/js/components/users/TtrUsersList.vue"));
const TtrUserViewInvitationDropdown = defineAsyncComponent(() => import("@/js/components/users/TtrUserViewInvitationDropdown.vue"));
const TtrVerifyPhone = defineAsyncComponent(() => import("@/js/components/auth/TtrVerifyPhone.vue"));
const TtrViewablePasswordFormInput = defineAsyncComponent(() => import("@/js/components/misc/TtrViewablePasswordFormInput.vue"));
const TtrWebNotification = defineAsyncComponent(() => import("@/js/components/misc/TtrWebNotification.vue"));
const TtrHubspotIdentifier = defineAsyncComponent(() => import("@/js/components/misc/TtrHubspotIdentifier.vue"));
const TtrPopupMenuWithTrigger = defineAsyncComponent(() => import("@/js/components/misc/TtrPopupMenuWithTrigger.vue"));
const TtrEditCalendarSyncSetting = defineAsyncComponent(() => import("@/js/components/calendarSyncSettings/TtrEditCalendarSyncSetting.vue"));
const TtrCalendarSyncSettingsList = defineAsyncComponent(() => import("@/js/components/calendarSyncSettings/TtrCalendarSyncSettingsList.vue"));
const TtrCustomWebhooksList = defineAsyncComponent(() => import("@/js/components/customWebhooks/TtrCustomWebhooksList.vue"));

const tokenMeta = document.head?.querySelector<HTMLMetaElement>('meta[name="company"]');
const company: number | null = tokenMeta ? Number(tokenMeta.content) : null;

const app = createApp({
  el: "#app",
  name: "TtrApp",
  pinia: pinia,
  created() {
    const userStore = useUserStore();

    if (typeof user !== "undefined" && user !== null) {
      userStore.setUser(user);
    }
    if (typeof company !== "undefined" && company !== null) {
      userStore.setCompany(company);
    }
    if (typeof statusMessage !== "undefined" && statusMessage !== null) {
      userStore.setStatus(statusMessage);
    }
    if (typeof error !== "undefined" && error !== null) {
      userStore.setError(error);
    }
    if (typeof billingError !== "undefined" && billingError !== null) {
      userStore.setBillingError(billingError);
    }
    if (typeof productType !== "undefined" && productType !== null) {
      userStore.setProductType(productType);
    }
  },
});

app.use(pinia);

app.use(PrimeVue, {
  theme: {
    preset: TtrSuccessPrimeVueTheme,
    options: {
      darkModeSelector: ".dark",
    },
  },
  ripple: true,
});

app.use(HighchartsVue);

//Primevue
app.directive("tooltip", Tooltip);
app.use(ConfirmationService);
app.use(ToastService);
app.component("PToast", Toast);
app.component("PMessage", Message); //replaces t-alert
app.component("PButton", Button); //replaces t-button
app.component("PCard", Card); //replaces t-card
app.component("PCheckbox", Checkbox); //replaces t-checkbox and t-checkbox-group
app.component("PDatePicker", DatePicker); //replaces t-datepicker
app.component("PDialog", Dialog); //replaces t-dialog and t-modal
app.component("PPopover", Popover); //replaces t-dropdown
app.component("PInputNumber", InputNumber); //replaces t-input (type number)
app.component("PFloatLabel", FloatLabel);
app.component("PInputText", InputText); //replaces t-input (type text)
app.component("PInputGroup", InputGroup); //replaces t-input-group
app.component("PInputGroupAddon", InputGroupAddon); //compliments t-input-group
app.component("PPaginator", Paginator); //replaces t-pagination
app.component("PRadioButton", RadioButton); //replaces t-radio and t-radio-group
app.component("PSelect", Select); //replaces t-select and t-rich-select
app.component("PSelectButton", SelectButton);
app.component("PTag", Tag); //replaces t-tag
app.component("PChip", Chip);
app.component("PTextarea", Textarea); //replaces t-textarea
app.component("PToggleSwitch", ToggleSwitch); //replaces t-toggle
app.component("PToggleButton", ToggleButton); //replaces t-toggle
app.component("PDataTable", DataTable);
app.component("PColumn", Column);
app.component("PColumnGroup", ColumnGroup);
app.component("PRow", Row);
app.component("PConfirmDialog", ConfirmDialog);
app.component("PMenu", Menu);
app.component("PAutoComplete", AutoComplete);
app.component("PTabs", Tabs);
app.component("PTab", Tab);
app.component("PTabList", TabList);
app.component("PTabPanels", TabPanels);
app.component("PTabPanel", TabPanel);
app.component("PDivider", Divider);

//Alerts and Notifications
app.component("TtrAlert", TtrAlert);
app.component("TtrActionLink", TtrActionLink);
app.component("TtrIntlCurrencyFormat", TtrIntlCurrencyFormat);
app.component("TtrInputGroup", TtrInputGroup);
app.component("TtrTooltip", TtrTooltip);
app.component("TtrIngestionProgressBar", TtrIngestionProgressBar);

//logs
app.component("TtrLogsConversations", TtrLogsConversations);
app.component("TtrLogsMessages", TtrLogsMessages);
app.component("TtrLogsAudits", TtrLogsAudits);

//Setup
app.component("TtrFirstRunModals", TtrFirstRunModals);
app.component("TtrBusinessHoursBox", TtrBusinessHoursBox);
app.component("TtrEditAgent", TtrEditAgent);
app.component("TtrAgentInvites", TtrAgentInvites);
app.component("TtrEditEwsAgent", TtrEditEwsAgent);
app.component("TtrBulkAgentSelect", TtrBulkAgentSelect);
app.component("TtrBulkAgentSelectGmail", TtrBulkAgentSelectGmail);
app.component("TtrTeamList", TtrTeamList);
app.component("TtrEditTeam", TtrEditTeam);
app.component("TtrFooterBanner", TtrFooterBanner);
app.component("TtrUploadTeamCsv", TtrUploadTeamCsv);
app.component("TtrGroupMailboxList", TtrGroupMailboxList);
app.component("TtrEditGroupMailbox", TtrEditGroupMailbox);
app.component("TtrEditCustomerGroup", TtrEditCustomerGroup);
app.component("TtrEditCustomerGroupHubspot", TtrEditCustomerGroupHubspot);
app.component("TtrEditCustomerGroupCrm", TtrEditCustomerGroupCrm);
app.component("TtrEditCustomerGroupSalesforce", TtrEditCustomerGroupSalesforce);
app.component("TtrEditCustomerGroupZoho", TtrEditCustomerGroupZoho);
app.component("TtrEditMicrosoftProxyAddress", TtrEditMicrosoftProxyAddress);
app.component("TtrAgentsList", TtrAgentsList);
app.component("TtrCustomersList", TtrCustomersList);
app.component("TtrCustomerGroupsList", TtrCustomerGroupsList);
app.component("TtrEditUser", TtrEditUser);
app.component("TtrUserViewInvitationDropdown", TtrUserViewInvitationDropdown);
app.component("TtrGoogleBulkAddDescriptionModal", TtrGoogleBulkAddDescriptionModal);
app.component("TtrMimecastAuthForm", TtrMimecastAuthForm);
app.component("TtrBulkAgentSelectMimecastSlow", TtrBulkAgentSelectMimecastSlow);
app.component("TtrEditInternalDomains", TtrEditInternalDomains);
app.component("TtrEditConversationThreadingStyle", TtrEditConversationThreadingStyle);
app.component("TtrEditCloseEmailAddresses", TtrEditCloseEmailAddresses);
app.component("TtrLeaveCalendarDay", TtrLeaveCalendarDay);
app.component("TtrLeaveDateRange", TtrLeaveDateRange);
app.component("TtrAccessTokens", TtrAccessTokens);
app.component("TtrOAuthClients", TtrOAuthClients);
app.component("TtrInviteAgent", TtrInviteAgent);
app.component("TtrChooseBilling", TtrChooseBilling);
app.component("TtrTimeZone", TtrTimeZone);
app.component("TtrWebNotification", TtrWebNotification);
app.component("TtrHubspotIdentifier", TtrHubspotIdentifier);
app.component("TtrPopupMenuWithTrigger", TtrPopupMenuWithTrigger);
app.component("TtrUploadContactsCsv", TtrUploadContactsCsv);
app.component("TtrTrialCompletionStatus", TtrTrialCompletionStatus);
app.component("TtrPromptToInstallOptimizerModal", TtrPromptToInstallOptimizerModal);
app.component("TtrPromptToRedirectModal", TtrPromptToRedirectModal);
app.component("TtrLinkMailbox", TtrLinkMailbox);
app.component("TtrOnboardingSummary", TtrOnboardingSummary);
app.component("TtrSelectCompanyToJoin", TtrSelectCompanyToJoin);
app.component("TtrJoinCompanyRequestDropdown", TtrJoinCompanyRequestDropdown);
app.component("TtrPendingJoinRequest", TtrPendingJoinRequest);
app.component("TtrEditCalendarSyncSetting", TtrEditCalendarSyncSetting);
app.component("TtrCalendarSyncSettingsList", TtrCalendarSyncSettingsList);
app.component("TtrCustomWebhooksList", TtrCustomWebhooksList);
app.component("TtrInstallOptimizerModal", TtrInstallOptimizerModal);

//Reports
app.component("TtrReportDateRange", TtrReportDateRange);
app.component("TtrThreadFilters", TtrThreadFilters);
app.component("TtrThreadFiltersPdf", TtrThreadFiltersPdf);
app.component("TtrReportsOverview", TtrReportsOverview);
app.component("TtrReportsProductivity", TtrReportsProductivity);
app.component("TtrReportsComparative", TtrReportsComparative);
app.component("TtrReportsInteractions", TtrReportsInteractions);
app.component("TtrReportsCustomer", TtrReportsCustomer);
app.component("TtrReportsTrend", TtrReportsTrend);
app.component("TtrReportsLeadSources", TtrReportsLeadSources);
app.component("TtrReportsTeam", TtrReportsTeam);
app.component("TtrReportsGroupMailbox", TtrReportsGroupMailbox);
app.component("TtrReportsSla", TtrReportsSla);
app.component("TtrReportsSalesRep", TtrReportsSalesRep);
app.component("TtrReportsAlerts", TtrReportsAlerts);
app.component("TtrStatLogModal", TtrStatLogModal);
app.component("TtrBlockReport", TtrBlockReport);
app.component("TtrAgentOptimizerData", TtrAgentOptimizerData);
app.component("TtrAddThreadsToDealModal", TtrAddThreadsToDealModal);
app.component("TtrViewsList", TtrViewsList);

//Filters
app.component("TtrAdvancedFilters", TtrAdvancedFilters);
app.component("TtrDomainFilters", TtrDomainFilters);
app.component("TtrEmailUsernameFilters", TtrEmailUsernameFilters);
app.component("TtrMaxReplyTimeFilters", TtrMaxReplyTimeFilters);
app.component("TtrReplyTimeGoals", TtrReplyTimeGoals);

//Buttons
app.component("TtrDeleteTeamButton", TtrDeleteTeamButton);
app.component("TtrDeleteGroupMailboxButton", TtrDeleteGroupMailboxButton);
app.component("TtrDeleteAccountButton", TtrDeleteAccountButton);
app.component("TtrDeleteCompanyButton", TtrDeleteCompanyButton);
app.component("TtrDownloadPdfButton", TtrDownloadPdfButton);
app.component("TtrDownloadExcelButton", TtrDownloadExcelButton);
app.component("TtrDownloadHybridButton", TtrDownloadHybridButton);
app.component("TtrReportActionsViews", TtrReportActionsViews);
app.component("TtrSidebar", TtrSidebar);
app.component("TtrNavbar", TtrNavbar);

//admin
app.component("TtrAdminUsersList", TtrAdminUsersList);
app.component("TtrB2BSaasReport", TtrB2BSaasReport);
app.component("TtrAdminAgentsList", TtrAdminAgentsList);
app.component("TtrAdminCompaniesList", TtrAdminCompaniesList);
app.component("TtrAdminTracking", TtrAdminTracking);
app.component("TtrAdminOptimizerAnalytics", TtrAdminOptimizerAnalytics);
app.component("TtrEditManualSubscription", TtrEditManualSubscription);
app.component("TtrEditPromotion", TtrEditPromotion);
app.component("TtrManualSubscriptionsList", TtrManualSubscriptionsList);

//crm
app.component("TtrCrmMapping", TtrCrmMapping);
app.component("TtrCrmAddActiveCampaignCredentials", TtrCrmAddActiveCampaignCredentials);
app.component("TtrCrmAddMaropostCredentials", TtrCrmAddMaropostCredentials);

//reseller
app.component("TtrResellerUsersList", TtrResellerUsersList);
app.component("TtrResellerAgentsList", TtrResellerAgentsList);

//auth
app.component("TtrVerifyPhone", TtrVerifyPhone);
app.component("TtrSuccessSetup", TtrSuccessSetup);
//two factor auth
app.component("TtrTwoFactorChallenge", TtrTwoFactorChallenge);

//sales
app.component("TtrEditLeadSource", TtrEditLeadSource);
app.component("TtrLeadSourceList", TtrLeadSourceList);
app.component("TtrEditDeal", TtrEditDeal);
app.component("TtrEditDealStage", TtrEditDealStage);
app.component("TtrListDeals", TtrListDeals);
app.component("TtrIgniteSetup", TtrIgniteSetup);
app.component("TtrDealStagesList", TtrDealStagesList);
app.component("TtrDealStagesSetup", TtrDealStagesSetup);
//other
app.component("TtrViewablePasswordFormInput", TtrViewablePasswordFormInput);
app.component("TtrUsersList", TtrUsersList);
app.component("TtrEditPagesPermissions", TtrEditPagesPermissions);
app.component("TtrEditOptimizerSettings", TtrEditOptimizerSettings);
app.component("TtrOptimizerSetupPreview", TtrOptimizerSetupPreview);
app.component("TtrCollapse", TtrCollapse);

app.use(ZiggyVue);

app.mount("#app");
